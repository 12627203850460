import request from '@/api/request.js'

/**
 * @Description: 司机
 */

// 分页查询
export function page(data) {
    return request({
        url: '/api/wmsDriver/page',
        method: 'POST',
        data
    })
}

// 全量查询
export function list(data) {
    return request({
        url: '/api/wmsDriver/list',
        method: 'POST',
        data
    })
}

export function listWmsDriver(data) {
    return request({
        url: '/api/wmsDriver/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detail(data) {
    return request({
        url: '/api/wmsDriver/detail',
        method: 'POST',
        data
    })
}

// 添加
export function add(data) {
    return request({
        url: '/api/wmsDriver/add',
        method: 'POST',
        data
    })
}

// 编辑
export function edit(data) {
    return request({
        url: '/api/wmsDriver/edit',
        method: 'POST',
        data
    })
}

// 删除
export function del(data) {
    return request({
        url: '/api/wmsDriver/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDelete(data) {
    return request({
        url: '/api/wmsDriver/batchDelete',
        method: 'POST',
        data
    })
}

