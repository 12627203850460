<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="编辑司机"
    :visible.sync="visible">

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="100px">


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="名称" prop="name">


          <el-input v-model="row.name" placeholder="请输入名称" clearable readonly disabled
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>
        </el-row>


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="手机" prop="phone">


          <el-input v-model="row.phone" placeholder="请输入手机" clearable readonly disabled
            :style="{width: '200px'}"></el-input>

        </el-form-item>
        </el-col>
        </el-row>


        <el-row :gutter="10">

        <el-col :span="12">
        <el-form-item label="状态" prop="status">



                <el-select v-model="row.status"
                clearable
                placeholder="请选择状态">
                    <el-option
                          v-for="item in enums['WmsDriverStatus']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>

        </el-form-item>
        </el-col>
        </el-row>


      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { edit } from "@/api/wms/wmsDriver"
import { uploadFile } from "@/api/common";

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      visible: false,
      row: {
      },
      rules: {
        id: [
        {
          required: true,
          message: '请输入主键',
          trigger: 'blur'
        }],
        name: [
        {
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        phone: [
        {
          required: true,
          message: '请输入手机',
          trigger: 'blur'
        }],
        status: [
        {
          required: true,
          message: '请输入状态',
          trigger: 'blur'
        }],
        isDelete: [
        {
          required: true,
          message: '请输入是否删除',
          trigger: 'blur'
        }],
      },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsDriverStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    upload(param){
        console.log("upload", param)
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success('上传成功')
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        edit(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
  width: 200px;
}
</style>
